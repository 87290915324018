import React from 'react';
import { Script } from 'nordic/script';
import PropTypes from 'prop-types';
import { suScopePath } from '../../helpers';
import { floxBrowserView } from '../../flox/flox-browser-view';
import TracksContext from '../../context/tracks-context';
import { tabTitle } from '../../helpers/tabTitle';

const tracking = {
  analytics: { analyticsDimensions: {} },
  melidataEventData: '',
};

const Note = (props) => {
  const { floxPreloadedState } = props;
  let { FloxView } = props;
  if (!FloxView) FloxView = floxBrowserView(floxPreloadedState);

  return (
    <>
      <Script on="now">{suScopePath}</Script>
      <TracksContext.Provider
        analyticsDimensions={tracking.analytics.analyticsDimensions}
        melidataEventData={tracking.melidataEventData}
        performEvent={() => ''}
      >
        <FloxView />
      </TracksContext.Provider>
    </>
  );
};

Note.propTypes = {
  FloxView: PropTypes.elementType,
  floxPreloadedState: PropTypes.shape({}),
};

export const hydrate = 'hydrateRoot';

export default Note;

export const getServerSideProps = async (req, res) => {
  const { platform } = req;
  const { floxPreloadedState, FloxView, title } = res.locals.state;
  return {
    props: {
      floxPreloadedState,
      FloxView,
    },
    settings: {
      title: tabTitle(platform, title),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'note-page',
});
